import Layout from '../layout/Layout';
export default function How() {
    return (
        <Layout>
            <main>
                <div id="translate-section">
                    <h1 style={{marginTop: "50px"}}>How it works</h1>
                </div>
            </main>
        </Layout>
    );
}
