import { Link, useNavigate, useLocation } from "react-router-dom";
import {
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Face6Icon from '@mui/icons-material/Face6';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { useEffect } from "react";
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from "react-i18next";
import axios from "axios";
export default function AdminSidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const handleRoutes = (path) => {
        // navigate action
        navigate(path);
    }
    const { i18n } = useTranslation();
    const { isLoggedIn, authLogin, authLogout, userInfo } = useAuth();

    const handleLogout = () => {
        localStorage.removeItem('bookstrans-user');
        localStorage.removeItem('bookstrans-token');
        authLogout();
        navigate("/");
    };

    useEffect(() => {
        const checkLogin = async () => {
            let token = localStorage.getItem('bookstrans-token');
            let lang = localStorage.getItem('bookstrans-lang');
            i18n.changeLanguage(lang);

            if (token) {
                axios.defaults.headers.common = {
                    Authorization: `Bearer ${token}`,
                };
                try {
                    const response = await axios.get("/api/account/signinbytoken");
                    authLogin(response.data);
                } catch (err) {
                    localStorage.removeItem("bookstrans-token");
                }
            }
        }
        checkLogin();
    }, []);

    useEffect(() => {
        let token = localStorage.getItem('bookstrans-token');
        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };
    }, [isLoggedIn]);

    return (
        <div id="admin-sidebar">
            <div className="full-width">
                <div id="admin-logo">
                    <Link to="/"><img src="/assets/imgs/logo.png" alt="logo" /></Link>
                </div>
                <div id="admin-menu">
                    <MenuList>
                        {userInfo?.admin && <MenuItem className={
                            'admin-link' + (pathname === '/admin/customers' || pathname === '/admin' ? ' active' : '')
                        } onClick={() => handleRoutes("/admin/customers")}>
                            <ListItemIcon>
                                <Face6Icon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>New sign ups</ListItemText>
                        </MenuItem>
                        }
                        {/* <MenuItem className={
                            'admin-link' + (pathname === '/admin/contacts' ? ' active' : '')
                        } onClick={() => handleRoutes("/admin/contacts")}>
                            <ListItemIcon>
                                <ContactsIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>Contact forms</ListItemText>
                        </MenuItem> */}
                        <MenuItem className={
                            'admin-link' + (pathname === '/admin/translate' ? ' active' : '')
                        } onClick={() => handleRoutes("/admin/translate")}>
                            <ListItemIcon>
                                <AutoFixHighIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>Translate</ListItemText>
                        </MenuItem>
                        <MenuItem className={
                            'admin-link' + (pathname === '/admin/setting' ? ' active' : '')
                        } onClick={() => handleRoutes("/admin/setting")}>
                            <ListItemIcon>
                                <SettingsApplicationsIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>Setting</ListItemText>
                        </MenuItem>
                    </MenuList>
                </div>
            </div>
            <MenuItem className="full-width" onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToAppIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </MenuItem>
        </div>
    );
}
