
export const LANGUAGES = [
    { label: 'Argentina', path: 'esAR', code: "es", language: "Spanish" },
    { label: 'Australia', path: 'enAU', code: "en", language: "English" },
    { label: 'Austria', path: 'deAT', code: "de", language: "Deutsch" },
    { label: 'Belarus', path: 'beBY', code: "be", language: "Belarusian" },
    { label: 'Belgium', path: 'nlBE', code: "nl", language: "Dutch" },
    { label: 'Brazil', path: 'ptBR', code: "pt-BR", language: "Portuguese" },
    { label: 'Canada', path: 'enCA', code: "en", language: "English" },
    { label: 'China', path: 'zhCN', code: "zh-CN", language: "Chinese" },
    { label: 'Colombia', path: 'esCO', code: "es", language: "Spanish " },
    { label: 'Croatia', path: 'hrHR', code: "hr", language: "Croatian" },
    { label: 'Czech Republic', path: 'csCZ', code: "cs", language: "Czech" },
    { label: 'Denmark', path: 'daDK', code: "da", language: "Danish" },
    { label: 'Finland', path: 'fiFI', code: "fi", language: "Finnish" },
    { label: 'France', path: 'frFR', code: "fr", language: "French" },
    { label: 'Germany', path: 'deDE', code: "de", language: "Deutsch" },
    { label: 'India', path: 'hiIN', code: "hi", language: "Hindi" },
    { label: 'Italy', path: 'itIT', code: "it", language: "Italy" },
    { label: 'Mexico', path: 'esMX', code: "es", language: "Spanish" },
    { label: 'Netherlands', path: 'nlNL', code: "nl", language: "Dutch" },
    { label: 'Norway', path: 'noNO', code: "no", language: "Norwegian" },
    { label: 'Poland', path: 'plPL', code: "pl", language: "Polish" },
    { label: 'Portugal', path: 'ptPT', code: "pt-PT", language: "Portuguese" },
    { label: 'Romania', path: 'roRO', code: "ro", language: "Romanian" },
    { label: 'Singapore', path: 'enSG', code: "ms", language: "Malay" },
    { label: 'Spain', path: 'esES', code: "es", language: "Spanish" },
    { label: 'Sweden', path: 'svSE', code: "sv", language: "Swedish" },
    { label: 'South Africa', path: 'zuZA', code: "af", language: "Afrikaans" },
    { label: 'Switzerland', path: 'deCH', code: "de", language: "Deutsch" },
    { label: 'Turkey', path: 'trTR', code: "tr", language: "Turkish" },
    { label: 'United Arab Emirates', path: 'arAE', code: "ar", language: "Arabic" },
    { label: 'Ukraine', path: 'ukUA', code: "uk", language: "Ukrainian" },
    { label: 'United Kingdom', path: 'enGB', code: "en-GB", language: "English" },
    { label: 'United States', path: 'enUS', code: "en", language: "English" },
];
