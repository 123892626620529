/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    IconButton
} from '@mui/material';
import Layout from '../layout/Layout';
import DragDropFile from '../components/DragDropFile';
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import axios from 'axios';
import { useAuth } from "../contexts/AuthContext";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactCountryFlag from "react-country-flag";
import { languages as countryLangs } from '../constants/countryLanguages';
import DownloadIcon from '@mui/icons-material/Download';
import TextEditable from '../components/TextEditable';

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { v4 as uuidv4 } from "uuid";
const EventSource = EventSourcePolyfill;

const serverUrl = process.env.REACT_APP_SERVER_URL;
const Tool = () => {
    const [euuid, setEuuid] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [eventDataReal, setEventDataReal] = useState(null);
    const [processStatus, setProcessStatus] = useState('');
    const [processPercent, setProcessPercent] = useState(0);
    const [processStep, setProcessStep] = useState(0);
    const [enableStart, setEnableStart] = useState(false);

    const [selectedTool, setSelectedTool] = useState("book");
    const [completeStep, setCompleteStep] = useState(-2);
    const [resultItems, setResultItems] = useState([]);
    const [pendingAction, setPendingAction] = useState(false);
    const [resultPendingAction, setResultPendingAction] = useState(false);
    const [progress, setProgress] = useState("");
    const [langItems, setLangItems] = useState([]);
    const { isLoggedIn, userInfo, setUserInfo } = useAuth();
    const fileInputRef = useRef(null);
    const { t } = useTranslation();
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);

    const [srcLanguage, setSrcLanguage] = useState('global');
    const [tarLangPath, setTarLangPath] = useState('global');
    const [tarLangs, setTarLangs] = useState(["global"]);
    const [srcLangPath, setSrcLangPath] = useState('global');

    const onHandleFile = (data) => {
        let tmpFile = data[0];
        let fileNameParts = tmpFile.name.split('.');
        let extention = fileNameParts[fileNameParts.length - 1];

        if (extention !== 'doc' && extention !== 'docx' && extention !== 'pdf') {
            toastr.clear();
            toastr.warning(t('Please select the valid document(doc, docx, pdf) file'));
            setFile([]);
            return;
        }

        let fileSize = Math.floor((1000 * tmpFile.size) / 1024 / 1024) / 1000;
        if (fileSize > 5) {
            toastr.clear();
            toastr.warning(t('The file size should be less than 5MB'));
            return;
        }

        setFile(data);
        if (file.length === 0) {
        } else {
            toastr.clear();
            toastr.success(t('The file loaded'));
        }
    };

    const handleRemoveFile = () => {
        setFile([]);
    };

    const handleSrcLanguageChange = (e) => {
        setSrcLangPath(e.target.value);
        setSrcLanguage(
            countryLangs.filter((item) => item.abbreviation === e.target.value)[0].languages[0].code
        );
    };

    const handleTarLanguageChange = (key) => (e) => {
        setTarLangs(tarLangs.map((item, index) => index === key ? e.target.value : item));
    };

    const handleTranslate = async () => {
        if (!file[0]) {
            toastr.clear();
            toastr.warning(t('Please select the book file'));
            return;
        }
        if (srcLangPath === 'global') {
            toastr.clear();
            toastr.warning(t('Please select your original language'));
            return;
        }

        let targetLanguages = tarLangs.filter(item => item !== "global");
        if (targetLanguages.length === 0) {
            toastr.clear();
            toastr.warning(t('Please select your target languages'));
            return;
        }

        if (resultItems.length !== 0) {
            setResultPendingAction(true);
            setResultItems([]);
            return;
        }

        if (!enableStart || langItems.length === 0) {
            setPendingAction(true);
            handleAddLanguage();
            return;
        } else {
            setEnableStart(false);
        }

        // Using the Fetch API
        setLoading(true);
        setCompleteStep(-1);

        let uploadedFile = null;
        setProgress(0);

        let tmpResultItems = [];
        for (let i = 0; i < langItems.length; i++) {
            const formData = new FormData();

            if (i === 0) {
                formData.append('book', file[0]);
            } else {
                formData.append('book', uploadedFile);
            }
            if (i === langItems.length - 1) {
                formData.append('end', true);
            }

            const eventUuid = uuidv4();
            setEuuid(eventUuid);
            formData.append('step', i);
            formData.append('uuid', eventUuid);
            formData.append('sl', srcLanguage);
            formData.append('tl', langItems[i].languages[0].code);

            try {
                const { data: result } = await axios.post(
                    `/api/tool/transbook`,
                    formData
                );
                const { link, tmpFile } = result;
                if (i === 0) uploadedFile = tmpFile;
                tmpResultItems.push(link);

                setResultItems([...tmpResultItems]);

                if (userInfo.free) {
                    setUserInfo({ ...userInfo, free: false });
                }
            } catch (err) {
                console.log(err);
                toastr.clear();
                toastr.warning(t(err.response.data));
                break;
            }
            setProgress(Math.round(100 * (i + 1) / langItems.length));
            setCompleteStep(i);
        }
        // setResultItems(tmpResultItems);
        //setCompleteStep(9999);
        setLoading(false);

    };

    const handleAddLanguage = () => {
        if (loading) return;
        if (resultItems.length > 0) {
            setCompleteStep(-2);
        }

        let isExist = langItems.filter(item => item.abbreviation === tarLangPath).length !== 0;
        if (isExist) return;

        let langItem = countryLangs.filter(item => tarLangs.includes(item.abbreviation));
        setLangItems([...langItem]);
        setEnableStart(true);
    }

    const handleRemoveLanguage = (index) => (event) => {
        if (loading) return;
        event.preventDefault();
        let newLangItems = [...langItems];
        newLangItems.splice(index, 1);
        setLangItems(newLangItems);

        let newResultItems = [...resultItems];
        newResultItems.splice(index, 1);
        setResultItems(newResultItems);
    }

    const handleDownload = (index) => {
        let downloadNameArr = resultItems[index].split("/");
        let downloadName = downloadNameArr[downloadNameArr.length - 1];

        fetch(resultItems[index]).then((response) => {
            response.blob().then((blob) => {
                const link = document.createElement('a');
                const fileURL =
                    window.URL.createObjectURL(blob);

                link.download = downloadName;
                link.href = fileURL;
                document.body.appendChild(link);
                console.log(link);
                // return;
                link.click();
                document.body.removeChild(link);
            });
        });
    };

    const handleDownloadAllFiles = () => {
        for (let i = 0; i < resultItems.length; i++) {
            setTimeout(
                () => {
                    handleDownload(i);
                },
                i * 1000 // Delay download every 200ms
            );
        }
    }

    useEffect(() => {
        if (enableStart && pendingAction && langItems.length !== 0) {
            handleTranslate();
            setPendingAction(false);
        }
    }, [langItems, enableStart, pendingAction])

    useEffect(() => {
        if (resultPendingAction && resultItems.length === 0) {
            handleTranslate();
            setResultPendingAction(false);
        }
    }, [resultItems, resultPendingAction])


    useEffect(() => {
        // event from server
        const token = localStorage.getItem("bookstrans-token");
        if (token) {
            const eventSource = new EventSource('/api/tool/transstatus', {
                headers: {
                    'Authorization': 'Bearer ' + token, // and/or any other headers you need
                },
            });

            eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setEventData(data);
                console.log(data);
            };

            return () => {
                eventSource.close();
            };
        }
    }, [])

    useEffect(() => {
        if (userInfo?.subscription) {
            if (userInfo.planType === 1) setTarLangs(["global", "global", "global"]);
            if (userInfo.planType === 2) setTarLangs(["global", "global", "global", "global", "global"]);
        }
    }, [userInfo])

    useEffect(() => {
        if (eventData?.uuid === euuid) {
            setEventDataReal({ ...eventData });
        }
    }, [eventData, euuid])

    return (
        <Layout>
            <main>
                <div id="translate-section">
                    <form id="edit-doc-form">
                        <div id="form-title">
                            <TextEditable type="h1" text="trans-form-title" center />
                            <TextEditable className="tool-des" text="edit-form-subtitle" center />
                        </div>

                        <TextEditable text="What do you need?" />

                        <Select
                            className="form-control tool-selection"
                            labelId="select-tool-label"
                            id="select-tool"
                            value={selectedTool}
                            label="Tool"
                            onChange={(e) => setSelectedTool(e.target.value)}
                        >
                            <MenuItem
                                value="book"
                            >
                                {t('Book translation')}
                            </MenuItem>
                        </Select>

                        <TextEditable text="Upload your main Book file and select  a language" />
                        <DragDropFile
                            onHandleFile={onHandleFile}
                            fileInputRef={fileInputRef}
                        />

                        <div className="language-selection">
                            <FormControl
                                sx={{ m: 1, minWidth: 0 }}
                                size="small"
                            >
                                <Select
                                    className="form-control"
                                    labelId="select-language-label"
                                    id="select-language"
                                    value={srcLangPath}
                                    label="Language"
                                    onChange={handleSrcLanguageChange}
                                >
                                    <MenuItem value="global" disabled>
                                        <img
                                            src={`/assets/imgs/flags/global.png`}
                                            className="language-flag"
                                            alt="language"
                                        />
                                        {t('Select your original language')}
                                    </MenuItem>
                                    {countryLangs.map((item, key) => (
                                        <MenuItem
                                            key={item.country}
                                            value={item.abbreviation}
                                        >
                                            <ReactCountryFlag
                                                countryCode={item.abbreviation}
                                                svg
                                                className="language-flag"
                                                title={item.abbreviation}
                                            />
                                            {item.country} - {item.languages[0].language}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <p className="output-languages">{t('Output language(s)')}</p>

                        {tarLangs.map((item, index) => <div className="language-selection" key={index}><FormControl
                            sx={{ m: 1, minWidth: 0 }}
                            size="small"
                        >
                            <Select
                                className="form-control select-language"
                                labelId="select-language-label"
                                value={item}
                                label="Language"
                                onChange={handleTarLanguageChange(index)}
                            >
                                <MenuItem value="global">
                                    <img
                                        src={`/assets/imgs/flags/global.png`}
                                        className="language-flag"
                                        alt="language"
                                    />
                                    {t('Select your target language')}
                                </MenuItem>
                                {countryLangs.map((item, key) => (
                                    <MenuItem
                                        key={item.country}
                                        value={item.abbreviation}
                                    >
                                        <ReactCountryFlag
                                            countryCode={item.abbreviation}
                                            svg
                                            className="language-flag"
                                            title={item.abbreviation}
                                        />
                                        {item.country} - {item.languages[0].language}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl></div>)}


                        {file.length !== 0 && (
                            <div className="files-action">
                                <span className="filename">{file[0].name}</span>
                                <button
                                    type="button"
                                    className="btn-file-action btn-remove"
                                    onClick={handleRemoveFile}
                                >
                                    Remove
                                </button>
                                <span className="filesize">
                                    {Math.floor(
                                        (1000 * file[0].size) / 1024 / 1024
                                    ) / 1000}
                                    MB
                                </span>
                            </div>
                        )}
                        <Button
                            onClick={handleTranslate}
                            className="btn-common btn-operate"
                        >
                            {t('Start translation')}
                            <img
                                src="/assets/imgs/icon-edit.png"
                                alt="ai"
                                className={
                                    'edit-icon' +
                                    (loading ? ' loading' : '')
                                }

                            />
                            <span className="trans-progress">{progress !== "" ? `${progress} %` : ""}</span>
                        </Button>
                        {isLoggedIn && userInfo.free && <p style={{ color: "#33a68e" }}>{t('*** You can try 30,000 charecters for free. ***')}</p>}

                        {langItems.length !== 0 && <div className="trans-lang-items-box">
                            <Button className="btn-download-all" variant="text" onClick={handleDownloadAllFiles}>{t('Download all files')}</Button>
                            {langItems.map((item, index) =>
                                <div className={"trans-lang-item" + (completeStep >= index ? " complete" : "")} key={index}>
                                    <div>
                                        <ReactCountryFlag
                                            countryCode={item.abbreviation}
                                            svg
                                            className="language-flag"
                                            title={item.abbreviation}
                                        />
                                        <span className="item-language">{item.languages[0].language}</span>
                                    </div>
                                    <div>
                                        <span className="item-download" onClick={() => handleDownload(index)}>
                                            <span>{t('Download file')}</span>
                                            <DownloadIcon />
                                            {completeStep + 1 === index && loading && eventDataReal?.uuid === euuid ? eventDataReal?.percent + "%" : ""}
                                        </span>
                                        <img src="/assets/imgs/icon-edit.png" className={"edit-icon" + ((completeStep + 1 === index && loading) ? " loading" : "")} alt="ai" />
                                    </div>
                                    <IconButton onClick={handleRemoveLanguage(index)}>
                                        <DeleteOutlineIcon color="white" />
                                    </IconButton>
                                </div>
                            )}
                        </div>}
                    </form>
                </div>
            </main>
        </Layout>
    );
};
export default Tool;
